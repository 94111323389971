/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    repeat: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 5.466V4H5a4 4 0 00-3.584 5.777.5.5 0 11-.896.446A5 5 0 015 3h6V1.534a.25.25 0 01.41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 01-.41-.192m3.81.086a.5.5 0 01.67.225A5 5 0 0111 13H5v1.466a.25.25 0 01-.41.192l-2.36-1.966a.25.25 0 010-.384l2.36-1.966a.25.25 0 01.41.192V12h6a4 4 0 003.585-5.777.5.5 0 01.225-.67z"/>',
    },
});
